var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_vm.isAdminGudang ? [_c('b-row', [_c('b-col', [_c('dashboard-gudang')], 1)], 1)] : _vm.isFinance ? [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c('dashboard-finance')], 1)], 1)] : _vm.isOwner ? [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c('dashboard-owner')], 1)], 1)] : _vm.isTerapis ? [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c('dashboard-terapis')], 1)], 1)] : [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.user.level.nama_level.toUpperCase() == 'REGISTER' ? _c('b-alert', {
    staticClass: "p-1",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('span', {
    staticClass: "text-dark"
  }, [_vm._v("Akun anda belum aktif, mohon untuk menunggu proses aktivasi atau hubungi admin! ")])]) : _vm._e()], 1), _vm.user.level.nama_level.toUpperCase() != 'REGISTER' ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4",
      "lg": "3"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "d-flex align-items-center justify-content-start"
  }, [_c('section', {
    staticClass: "icon"
  }, [_c('b-avatar', {
    attrs: {
      "variant": "light-success",
      "size": "50"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "DollarSignIcon"
    }
  })], 1)], 1), _c('section', {
    staticClass: "info ml-2"
  }, [_c('strong', {
    staticClass: "d-block",
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_c('small', [_vm._v("Komisi Affiliate")])]), _c('h2', [_c('strong', [_vm._v("Rp. " + _vm._s(_vm.rekapKomisi == null ? _vm.formatRupiah(0) : _vm.formatRupiah(_vm.rekapKomisi.dapat_ditarik)))])])])])])], 1) : _vm._e(), _vm.isCabang && _vm.user.level.nama_level.toUpperCase() != 'REGISTER' ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4",
      "lg": "3"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "d-flex align-items-center justify-content-start"
  }, [_c('section', {
    staticClass: "icon"
  }, [_c('b-avatar', {
    attrs: {
      "variant": "light-info",
      "size": "50"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "DollarSignIcon"
    }
  })], 1)], 1), _c('section', {
    staticClass: "info ml-2"
  }, [_c('strong', {
    staticClass: "d-block",
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_c('small', [_vm._v("Komisi Cabang")])]), _c('h2', [_c('strong', [_vm._v("Rp. " + _vm._s(_vm.rekapKomisiCabang == null ? _vm.formatRupiah(0) : _vm.formatRupiah(_vm.rekapKomisiCabang.dapat_ditarik)))])])])])])], 1) : _vm._e(), _vm.user.level.nama_level.toUpperCase() != 'REGISTER' ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4",
      "lg": "3"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "d-flex align-items-center justify-content-start"
  }, [_c('section', {
    staticClass: "icon"
  }, [_c('b-avatar', {
    attrs: {
      "variant": "light-danger",
      "size": "50"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "StopCircleIcon"
    }
  })], 1)], 1), _c('section', {
    staticClass: "info ml-2"
  }, [_c('strong', {
    staticClass: "d-block",
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_c('small', [_vm._v("Poin Anda")])]), _c('h2', [_c('strong', [_vm._v(_vm._s(_vm.rekapPoin ? _vm.rekapPoin.dapat_ditarik : 0))])])])])])], 1) : _vm._e(), _vm.user.level.nama_level.toUpperCase() != 'REGISTER' ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4",
      "lg": "3"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "d-flex align-items-center justify-content-start"
  }, [_c('section', {
    staticClass: "icon"
  }, [_c('b-avatar', {
    attrs: {
      "variant": "light-warning",
      "size": "50"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "UserCheckIcon"
    }
  })], 1)], 1), _c('section', {
    staticClass: "info ml-2"
  }, [_c('strong', {
    staticClass: "d-block",
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_c('small', [_vm._v("Konsumen Anda")])]), _c('h2', [_c('strong', [_vm._v("0")])])])])])], 1) : _vm._e()], 1), _vm.cekAffiliate ? _c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('h4', {
    staticClass: "text-bold"
  }, [_vm._v("Bagaimana menggunakan program referral")]), _c('section', {
    staticClass: "icon"
  }, [_c('feather-icon', {
    staticClass: "text-success mr-1",
    attrs: {
      "size": "20",
      "icon": "CheckCircleIcon"
    }
  }), _vm._v("1. Salin tautan rujukan")], 1), _c('section', {
    staticClass: "icon"
  }, [_c('feather-icon', {
    staticClass: "text-success mr-1",
    attrs: {
      "size": "20",
      "icon": "CheckCircleIcon"
    }
  }), _vm._v("2. Bagikan dengan teman anda dan orang lain")], 1), _c('section', {
    staticClass: "icon"
  }, [_c('feather-icon', {
    staticClass: "text-success mr-1",
    attrs: {
      "size": "20",
      "icon": "CheckCircleIcon"
    }
  }), _vm._v("3. Hasilkan uang dari langganan mereka")], 1)]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('h4', {
    staticClass: "text-bold"
  }, [_vm._v("Tautan Referensi Anda")]), _vm._v(" Undang teman Anda dan orang lain dan dapatkan komisi dari langganan mereka "), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('b-form-input', {
    staticClass: "mt-1",
    staticStyle: {
      "font-size": "13px"
    },
    attrs: {
      "readonly": "",
      "value": 'https://balanja.id/aff?member=' + _vm.user.karyawan.id
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-button', {
    staticClass: "mt-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.copyUrl('https://balanja.id/aff?member=' + _vm.user.karyawan.id);
      }
    }
  }, [_vm._v("Copy Link")])], 1)], 1)], 1)], 1)], 1) : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }