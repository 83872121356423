var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.user.level.nama_level.toUpperCase() == 'REGISTER' ? _c('b-alert', {
    staticClass: "p-1",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('span', {
    staticClass: "text-dark"
  }, [_vm._v("Akun anda belum aktif, mohon untuk menunggu proses aktivasi atau hubungi admin! ")])]) : _vm._e()], 1), _vm.user.level.nama_level.toUpperCase() != 'REGISTER' ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "d-flex align-items-center justify-content-start"
  }, [_c('section', {
    staticClass: "icon"
  }, [_c('b-avatar', {
    attrs: {
      "variant": "light-warning",
      "size": "50"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "DollarSignIcon"
    }
  })], 1)], 1), _c('section', {
    staticClass: "info ml-2"
  }, [_c('strong', {
    staticClass: "d-block",
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_c('small', [_vm._v("Komisi Belum ditarik")])]), _c('h2', [_c('strong', [_vm._v("Rp. " + _vm._s(_vm.rekapKomisi == null ? _vm.formatRupiah(0) : _vm.formatRupiah(_vm.rekapKomisi.dapat_ditarik)))])])])])])], 1) : _vm._e(), _vm.user.level.nama_level.toUpperCase() != 'REGISTER' ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "d-flex align-items-center justify-content-start"
  }, [_c('section', {
    staticClass: "icon"
  }, [_c('b-avatar', {
    attrs: {
      "variant": "light-success",
      "size": "50"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "DollarSignIcon"
    }
  })], 1)], 1), _c('section', {
    staticClass: "info ml-2"
  }, [_c('strong', {
    staticClass: "d-block",
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_c('small', [_vm._v("Komisi sudah ditarik")])]), _c('h2', [_c('strong', [_vm._v(_vm._s(_vm.rekapPoin ? _vm.rekapPoin.dapat_ditarik : 0))])])])])])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }